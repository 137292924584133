






















































































































import {Component, Prop, VModel, Vue} from 'vue-property-decorator';
import Customer from '@/models/Customer';
import {namespace} from 'vuex-class';
import Address from '@/models/Address';
import {Permission} from '@/misc/enums/permission.enum';

const CustomerStore = namespace('customer');

/**
 * Simple component that lists all customer in a table
 */
@Component({
  computed: {
    Permission() {
      return Permission;
    },
  },
  components: {
    SideCardComponent: () => import(
      '@/components/shared/SideCard/SideCard.component.vue'),
    UserInitialsComponent: () => import(
      /* webpackChunkName: "UserInitialsComponent" */
      '@/components/user/UserInitials.component.vue'),
    CustomerManageComponent: () => import(
      /* webpackChunkName: "CustomerManageComponent" */
      '@/components/customer/CustomerManage.component.vue'),
    TableComponent: () => import(
      '@/components/shared/table/Table.component.vue'),
    ActionMenuComponent: () => import(
      '@/components/shared/table/ActionMenu.component.vue'),
  },
})
export default class CustomerTableComponent extends Vue {

  @CustomerStore.Action('deleteCustomerAction')
  public deleteCustomerAction!: (customer: Customer) => Promise<Customer>;
  @VModel({default: '', type: String})
  public searchString!: string;
  @Prop({default: () => []})
  private customers!: Customer[];
  private showCustomerModal: boolean = false;
  private showDeleteDialog: boolean = false;
  private showChangeStatusDialog: boolean = false;
  private selectedCustomer: Customer = new Customer();
  @CustomerStore.Action('editCustomerAction')
  private editCustomerAction!: (customer: Customer) => Promise<Customer>;
  @CustomerStore.Mutation('storeCustomer')
  private storeCustomer!: (customer?: Customer) => void;
  @CustomerStore.Action('loadCustomersAction')
  private loadCustomersAction!: (companyId: string) => Promise<Customer[]>;
  @CustomerStore.Action('loadCustomerAction')
  private loadCustomerAction!: (customerId: string) => Promise<Customer>;

  private get importantKeys() {
    return {name: String, address: Address, contactPerson: {firstName: String, lastName: String}};
  }

  get isActive() {
    const inactive: string = (this.$t('GENERAL.INACTIVE') as string).toLowerCase();
    const active: string = (this.$t('GENERAL.ACTIVE') as string).toLowerCase();

    return {
      opposite: this.selectedCustomer.active ? inactive : active,
    };
  }

  private get tableHeaders(): any {
    return [
      {text: this.$t('CUSTOMERS_OVERVIEW.TABLE.NAME'), value: 'name', width: '30%', class: 'justify-start'},
      {
        text: this.$t('CUSTOMERS_OVERVIEW.TABLE.ADDRESS'),
        value: 'address',
        width: '30%',
        class: 'justify-start',
      },
      {
        text: this.$t('CUSTOMERS_OVERVIEW.TABLE.CONTACT_PERSON'),
        value: 'contactPerson',
        width: '15%',
        class: 'justify-start',
      },
      {
        text: this.$t('CUSTOMERS_OVERVIEW.TABLE.OBJECTS'),
        value: 'locationsCount',
        width: '10%',
        class: 'justify-center',
      },
      {text: this.$t('GENERAL.STATUS'), value: 'active', width: '10%', class: 'justify-center'},
      {text: '', value: 'actions', width: '5%', class: 'justify-start'},
    ];
  }

  public onCustomerDeleteClick(customer: Customer) {
    this.showDeleteDialog = true;
    this.selectedCustomer = customer;
  }

  public async onCustomerDelete(customer: Customer) {
    this.showDeleteDialog = false;
    try {
      await this.deleteCustomerAction(customer);
      this.$notifySuccessSimplified('CUSTOMER_MANAGE.NOTIFICATIONS.DELETE.SUCCESS');
      await this.loadCustomersAction(this.$route.params.companyId);
    } catch (e) {
      this.$notifyErrorSimplified('CUSTOMER_MANAGE.NOTIFICATIONS.DELETE.ERROR');
    }
  }

  public onCustomerStatusToggleClick(event: Event, customer: Customer) {
    if (this.$userRoleHandler.hasPermission(Permission.CUSTOMER_UPDATE_OWN)) {
      this.showChangeStatusDialog = true;
      this.selectedCustomer = customer;
      event.stopPropagation();
    }
  }

  public async onCustomerChangeStatus(updatedCustomer: Customer) {
    // was in finally{} before, but that applied an ugly delay
    this.showChangeStatusDialog = false;

    try {
      // Save these two values temporarily, because the backend does not calculate them at update
      const locationsCountActive = updatedCustomer.locationsCountActive;
      const locationsCount = updatedCustomer.locationsCount;

      updatedCustomer.active = !updatedCustomer.active;
      updatedCustomer = (await this.editCustomerAction(updatedCustomer));

      // Should this be in the backend?
      updatedCustomer.locationsCountActive = locationsCountActive;
      updatedCustomer.locationsCount = locationsCount;

      if (updatedCustomer.active) {
        this.$notifySuccessSimplified('CUSTOMER_MANAGE.NOTIFICATIONS.ACTIVATE');
      } else {
        this.$notifySuccessSimplified('CUSTOMER_MANAGE.NOTIFICATIONS.DEACTIVATE');
      }
    } catch (e) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }
  }

  public async onCustomerEditClick(customer: Customer) {
    this.selectedCustomer = await this.loadCustomerAction(customer.id!);
    this.showCustomerModal = true;
  }

  public async closeManageComponent(updatedCustomer: Customer) {
    if (updatedCustomer) {
      this.storeCustomer(updatedCustomer);
      await this.loadCustomersAction(this.$route.params.companyId);
    }
    this.showCustomerModal = false;
  }

  public activeLocations(customer: Customer): number {
    if (!customer.active) {
      return 0;
    }
    return customer.locationsCountActive;
  }

  private getColor(color: string): string {
    return this.$colorHandler.getThemeColor(color);
  }

  private onRowClicked(item: Customer) {
    this.$router.push({
      name: 'customerDashboard',
      params: {
        companyId: this.$route.params.companyId,
        customerId: item.id!,
      },
    });
  }
}
